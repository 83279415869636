<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <b-tab
      v-for="(config, key) in configs"
      :key="key"
    >
      <!-- title -->
      <template #title>
        <router-link
          :to="{name: 'general-settings', params: {group: config.groupname}}"
          tag="div"
        >
          <feather-icon
            :icon="config.icon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold text-capitalize"> {{ config.groupname.replace(/_/g, ' ') }} </span>
        </router-link>
      </template>
      <router-view :config="config.items" />
    </b-tab>
  </b-tabs>
</template>

<script>
import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  data() {
    return {
      configs: [],
    }
  },
  watch: {
    configs() {
      if (!this.$route.params.group) {
        this.$router.push({
          name: 'general-settings',
          params: {
            group: this.configs[0].groupname,
          },
        })
      } else {
        this.$router.push({
          name: 'general-settings',
          params: {
            group: this.$route.params.group,
          },
        })
      }
    },
  },
  created() {
    if (!this.$route.params.group) {
      this.$router.push({
        name: 'general-settings',
        params: {
          group: this.configs[0].groupname,
        },
      })
    } else {
      this.$router.push({
        name: 'general-settings',
        params: {
          group: this.$route.params.group,
        },
      })
    }
  },
  async mounted() {
    await this.getConfigs()
  },
  methods: {
    getConfigs() {
      axios.get('configs?limit=10000').then(res => {
        const config = res.data.data
        const payload = _(config).groupBy('group').map((item, group) => ({
          icon: item.find(value => value.meta && value.meta.icon)?.meta?.icon,
          items: item,
          groupname: group,
        })).value()
        this.configs = payload
      })
    },
  },

}
</script>

<style>

</style>
